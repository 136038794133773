@import 'styles/theme-colors';
@import 'components/Organization/styles';

@mixin label {
	.label {
		font-size: 0.9rem;
		font-weight: 500;
		color: theme-color("gray-700");
	}
}

@mixin TitleGenerator {
	@include label;

	.SelectTitleOption {
		padding-left: .5rem;
		padding-right: .5rem;

		.TitleOption {
			padding: 0 .5rem;
			border-radius: 0.25rem;

			&:not(:first-child) {
				margin-top: .5rem;
			}

			&:hover {
				background-color: theme-color("gray-200");
			}
		}
	}
}

@mixin GenerateConfig {
	@include label;
}

.Article {
	.OrganizationInfoCard {
		@include OrganizationInfoCard; //components/Organization/Organization
	}

	.config-cards-wrapper {
		& > .card {
			&:not(:first-child) {
				margin-top: .5rem;
			}
		}

		.TitleGenerator {
			@include TitleGenerator;
		}

		.GenerateConfig {
			@include GenerateConfig;
		}
	}
}
