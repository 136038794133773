@import 'styles/theme-colors';

@mixin OrganizationInfoCard {
	position: sticky;
	top: 16px;
}

.Organization {
	.OrganizationInfoCard {
		@include OrganizationInfoCard;
	}
}
