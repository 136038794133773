.card {
	.card-header,
	.card-footer {
		padding: .5rem 1rem;
	}

	.card-body {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
