@import 'styles/theme-colors';

@mixin Editor {
	.Editor-Root {
		.ql-toolbar {
			position: sticky;
			top: 0;
			z-index: 1;
			background-color: white;
		}

		.ql-container {// this is the wrapper of the actual editor
			transition: background-color .15s ease-out;

			&.ql-disabled {
				background-color: theme-color("light");
			}

			@import '~bootstrap/scss/bootstrap-reboot';
		}
	}
}

.EditArticle {
	.Editor {
		@include Editor;
	}

	.card-footer {
		position: sticky;
		bottom: 0;
		background-color: #f8f8f8;
	}
}
