@import 'styles/theme-colors';

.EditOrganizationPopup {
	.form-group {
		.form-label {
			font-size: 0.9rem;
			font-weight: 500;
			color: theme-color("gray-700");
			margin-bottom: 0.25rem;
		}

		.form-text {
			padding-left: 1px;
			color: theme-color("gray-600");
		}
	}
}
