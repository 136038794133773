@import 'styles/theme-colors';

.StatusBadge {
	.simple-badge {
		text-transform: capitalize;
	}

	button#status-selector {
		font-size: 0.8rem;
	}

	.dropdown-item {
		text-transform: capitalize;

		&:active {
			background: none;
		}

		&.active {
			background: theme-color("gray-300");
			color: theme-color("dark");
			font-weight: 500;
		}
	}
}
