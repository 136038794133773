.line-item-wrapper {
	justify-content: space-between;

	&:not(:first-child) {
		margin-top: .75rem;
	}

	.item-wrapper {
		flex-direction: column;

		&:nth-child(even) {
			text-align: right;
		}

		.label {
			font-size: 0.8rem;
			font-weight: 00;
			color: theme-color("gray-600");
			text-transform: uppercase;
		}

		.value {
			font-size: 1.1rem;
		}
	}
}
